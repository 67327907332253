import type { ChangeEvent } from "react";
import { useCallback, useEffect, useState } from "react";
import type { Basic } from "unsplash-js/src/methods/photos/types";
import { t } from "@/i18n-js/instance";
import { ASPECT_RATIO } from "@circle-react/components/constants";
import { ModalWrapper } from "@circle-react-shared/TrixEditor/InlineModalSelector";
import { Uploader } from "../Uploader";
import { ImageCropper } from "./Cropper";
import type { Area, Point } from "./Cropper";
import { FileUploadMethods } from "./FileUploadMethods";
import { aspectRatioLabels } from "./constants";
import {
  checkIsImageFile,
  createRandomName,
  getCroppedImage,
  isImageURL,
  readURL,
  searchUnsplash,
} from "./functions";

export interface ImageEditorModalWithCropProps {
  aspectRatio?: number;
  attachImage?: ((itemUrl: string, signedId: string) => void) | null;
  closeModal: () => void;
  customAspectRatioHelpText?: string;
  formClass?: string;
  hasFile?: boolean;
  hideEmbedUrl?: boolean;
  hideUnsplash?: boolean;
  inputFieldName?: string;
  isCropEnabled?: boolean;
  saveImage?: ((signedId: string) => void) | null;
  setHasFile: (hasFile: boolean) => void;
  showModal?: boolean;
  title?: string;
  uploadProgress: (progress: number, fileName: string) => void;
  setIsLoading?: (isLoading: boolean) => void;
}

export const ImageEditorModalWithCrop = ({
  aspectRatio = ASPECT_RATIO.DEFAULT,
  attachImage = null,
  closeModal,
  customAspectRatioHelpText = "",
  formClass = "",
  hasFile = false,
  hideEmbedUrl = false,
  hideUnsplash = false,
  inputFieldName = "",
  isCropEnabled = false,
  setIsLoading = () => false,
  saveImage = null,
  setHasFile,
  showModal = false,
  title = "",
  uploadProgress,
}: ImageEditorModalWithCropProps) => {
  const [fileList, setFileList] = useState<FileList>();
  const [stringFile, setStringFile] = useState<string>();
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [cropArea, setCropArea] = useState<Area | null>(null);
  const [zoom, setZoom] = useState<number>(1);
  const [embedUrl, setEmbedUrl] = useState<string>("");
  const [unsplashResults, setUnsplashResults] = useState<Basic[]>([]);
  const [imageError, setImageError] = useState<string>("");

  const shouldShowImageCropper = hasFile && isCropEnabled;

  const aspectRatioHelpText = customAspectRatioHelpText
    ? customAspectRatioHelpText
    : aspectRatioLabels[aspectRatio] || "";

  const setUnsplash = async (query: string) => {
    const results = await searchUnsplash(query);
    setUnsplashResults(results);
  };

  useEffect(() => {
    void setUnsplash("wallpapers");
  }, []);

  const addUnsplash = async (item: Basic) => {
    setStringFile(item.urls.regular);
    setHasFile(true);
    try {
      setIsLoading(true);
      const response = await fetch(item.urls.regular);
      const blob = await response.blob();
      const file = new File([blob], `unsplash-${item.id}.jpg`, {
        type: "image/jpeg",
      });

      const uploader = new Uploader(
        file,
        afterUpload,
        formClass,
        inputFieldName,
        uploadProgress,
      );

      if (!isCropEnabled) {
        closeModal();
        uploader.start();
      }
    } catch (error) {
      console.error("Error handling Unsplash image:", error);
      setImageError(t("image_upload.unsplash_error"));
    }
  };

  const insertFiles = async (files: FileList | null) => {
    if (!files) return;
    setFileList(files);
    setIsLoading(true);

    const filesArray = Array.from(files);

    for (const file of filesArray) {
      const isImageFile = checkIsImageFile(file["type"]);
      if (isImageFile) {
        setImageError("");
        const parsedFile = await readURL(file);
        setHasFile(true);
        setStringFile(parsedFile);

        // Handle direct upload if cropping is disabled
        if (!isCropEnabled) {
          closeModal();
          const uploader = new Uploader(
            file,
            afterUpload,
            formClass,
            inputFieldName,
            uploadProgress,
          );
          uploader.start();
        }
      } else {
        setIsLoading(false);
        setImageError(t("image_upload.invalid_file_type_error"));
      }
    }
  };

  const onChangeEmbedUrl = (event: ChangeEvent<HTMLInputElement>) => {
    setImageError("");
    setEmbedUrl(event.target.value);
  };

  const addEmbed = async () => {
    try {
      await isImageURL(embedUrl);
      setIsLoading(true);
      setStringFile(embedUrl);
      setHasFile(true);

      // Fetch and create file from URL, similar to Unsplash flow
      const response = await fetch(embedUrl);
      const blob = await response.blob();
      const file = new File([blob], `embed-${createRandomName()}.jpg`, {
        type: response.headers.get("content-type") || "image/jpeg",
      });

      // Create and start uploader directly for non-crop flow
      if (!isCropEnabled) {
        closeModal();
        const uploader = new Uploader(
          file,
          afterUpload,
          formClass,
          inputFieldName,
          uploadProgress,
        );
        uploader.start();
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setImageError(error.message);
      }
    }
  };

  const onCropComplete = useCallback((_: Area, croppedAreaPixels: Area) => {
    setCropArea(croppedAreaPixels);
  }, []);

  const saveCroppedImage = useCallback(async () => {
    try {
      setIsLoading(true);
      if (!stringFile || !cropArea) {
        throw new Error(t("image_upload.file_cropped_area_error"));
      }

      const fileArray = fileList ? Array.from(fileList) : [];
      const firstFile = fileArray ? fileArray[0] : null;

      const fileName = firstFile ? firstFile.name : createRandomName();
      const fileType = firstFile ? firstFile.type : "image/jpeg";

      const croppedImage = await getCroppedImage(
        stringFile,
        cropArea,
        fileName,
        fileType,
      );
      closeModal();
      removeImage();

      const uploader = new Uploader(
        croppedImage,
        afterUpload,
        formClass,
        inputFieldName,
        uploadProgress,
      );

      uploader.start();
    } catch (error: unknown) {
      setIsLoading(false);
      console.error(error);
      if (error instanceof Error) {
        setImageError(error.message);
      }
    }
  }, [cropArea]);

  const afterUpload = useCallback(
    (itemUrl: string, signedId: string) => {
      if (attachImage) {
        attachImage(itemUrl, signedId);
      }
      if (saveImage) {
        saveImage(signedId);
      }
      setIsLoading(false);
    },
    [attachImage, saveImage],
  );

  const removeImage = () => {
    setStringFile(undefined);
    setFileList(undefined);
    setCrop({ x: 0, y: 0 });
    setHasFile(false);
    setEmbedUrl("");
  };

  return (
    <div className="editor-modal">
      <ModalWrapper
        onClose={closeModal}
        footerActions={{
          onCancel: removeImage,
          onSaveChanges: saveCroppedImage,
        }}
        shouldShowActionsFooter={hasFile && isCropEnabled}
        title={title}
        isOpen={showModal}
      >
        {shouldShowImageCropper && (
          <ImageCropper
            file={stringFile}
            crop={crop}
            onCropComplete={onCropComplete}
            aspectRatio={aspectRatio}
            zoom={zoom}
            setZoom={setZoom}
            setCrop={setCrop}
            imageError={imageError}
          />
        )}
        {!hasFile && (
          <FileUploadMethods
            hideUnsplash={hideUnsplash}
            hideEmbedUrl={hideEmbedUrl}
            unsplashResults={unsplashResults}
            searchUnsplash={setUnsplash}
            addUnsplash={addUnsplash}
            imageError={imageError}
            insertFiles={insertFiles}
            aspectRatioHelpText={aspectRatioHelpText}
            embedUrl={embedUrl}
            onChangeEmbedUrl={onChangeEmbedUrl}
            addEmbed={addEmbed}
          />
        )}
      </ModalWrapper>
    </div>
  );
};
