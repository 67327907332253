import type { FormEvent, MouseEvent } from "react";
import { useState } from "react";
import { t } from "@/i18n-js/instance";
import { useFocusOnLoad } from "../../../../hooks/useFocusOnLoad";
import { useEditorApi } from "../TrixEditorContext";
import { embedContent } from "../helpers";

const defaultHelperText = t("trix_editor.modal_copy.embed_helper_text");
interface EmbedFormProps {
  onClose?: () => void;
  embedPlaceholder: string;
  embedDesc?: string;
  helperText?: string;
  embedBtn: string;
}
export const EmbedForm = ({
  onClose,
  embedPlaceholder: placeholder,
  embedDesc: description,
  helperText,
  embedBtn: button,
}: EmbedFormProps) => {
  const { trixApiWrapper } = useEditorApi();
  const [value, setValue] = useState("");
  const inputRef = useFocusOnLoad<HTMLInputElement>();

  const embedUrlContent = async () => {
    onClose && onClose();
    await embedContent({ content: value, trixApiWrapper });
  };

  const onSubmit = async (
    event: FormEvent<HTMLFormElement> | MouseEvent<HTMLElement>,
  ) => {
    event.preventDefault();
    await embedUrlContent();
  };

  const hasDescription = Boolean(description);
  const hasHelperText = helperText !== defaultHelperText;
  const shouldShowHelperText = !hasDescription && hasHelperText;

  return (
    <form onSubmit={onSubmit}>
      <input
        ref={inputRef}
        value={value}
        style={{ borderWidth: 1 }}
        className="upload-input placeholder:text-light placeholder:opacity-100"
        placeholder={placeholder}
        onChange={event => {
          setValue(event.target.value);
        }}
        type="text"
      />
      <button
        type="submit"
        className="btn btn-primary upload-btn"
        onClick={onSubmit}
      >
        {button}
      </button>
      <div className="upload-embed__description">
        {hasDescription && description}
        {shouldShowHelperText && helperText}
        {!hasHelperText && (
          <span>
            {t("trix_editor.embed_form.works_with")}{" "}
            <a
              href="https://iframely.com/domains"
              target="_blank"
              rel="noreferrer"
            >
              {t("trix_editor.embed_form.content_providers")}
            </a>
            {t("trix_editor.embed_form.including")}
          </span>
        )}
      </div>
    </form>
  );
};
