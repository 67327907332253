import { t } from "@/i18n-js/instance";

const embedLink = t("trix_editor.modal_copy.embed_link");
const pasteHttps = t("trix_editor.modal_copy.paste_https");

export type ModalCopyType =
  | "image"
  | "pdf"
  | "video"
  | "audio"
  | "file"
  | "embed"
  | "youtube"
  | "wistia"
  | "vimeo"
  | "loom"
  | "soundcloud"
  | "spotify"
  | "simplecast"
  | "instagram"
  | "facebook"
  | "twitter"
  | "typeform"
  | "airtable"
  | "videoask"
  | "github"
  | "googlemaps"
  | "codepen";

export interface ModalCopy {
  embedPlaceholder: string;
  embedDesc: string;
  dropTitle: string;
  helperText: string;
  embedBtn: string;
  itemName: string;
}

export const modalCopy = (type?: ModalCopyType): ModalCopy => {
  let embedPlaceholder = "";
  let embedDesc = "";
  let dropTitle = "";
  let helperText = "";
  let embedBtn = "";
  let itemName = "";

  switch (type) {
    case "image":
      embedPlaceholder = t("trix_editor.modal_copy.paste_image_file_link");
      dropTitle = t("trix_editor.modal_copy.drop_images_upload");
      helperText = t("trix_editor.modal_copy.works_any_image");
      embedBtn = t("trix_editor.modal_copy.embed_image");
      itemName = t("trix_editor.modal_copy.image");
      break;
    case "pdf":
      embedPlaceholder = t("trix_editor.modal_copy.https_placeholder");
      dropTitle = t("trix_editor.modal_copy.drop_pdf");
      helperText = t("trix_editor.modal_copy.works_pdf");
      embedBtn = t("trix_editor.modal_copy.embed_pdf");
      itemName = t("trix_editor.modal_copy.pdf");
      break;
    case "video":
      embedPlaceholder = t("trix_editor.modal_copy.paste_video_link");
      embedDesc = t("trix_editor.modal_copy.works_youtube");
      dropTitle = t("trix_editor.modal_copy.drop_video");
      helperText = t("trix_editor.modal_copy.upload_mp4");
      embedBtn = t("trix_editor.modal_copy.embed_video");
      itemName = t("trix_editor.modal_copy.video");
      break;
    case "audio":
      embedPlaceholder = t("trix_editor.modal_copy.https_placeholder");
      dropTitle = t("trix_editor.modal_copy.drop_audio");
      helperText = t("trix_editor.modal_copy.works_audio");
      embedBtn = t("trix_editor.modal_copy.embed_audio");
      itemName = t("trix_editor.modal_copy.audio");
      break;
    case "file":
      embedPlaceholder = t("trix_editor.modal_copy.paste_file_link");
      dropTitle = t("trix_editor.modal_copy.drop_file");
      helperText = t("trix_editor.modal_copy.works_links");
      embedBtn = embedLink;
      itemName = t("trix_editor.modal_copy.file");
      break;
    case "embed":
      embedPlaceholder = pasteHttps;
      dropTitle = t("trix_editor.modal_copy.drop_file");
      helperText = t("trix_editor.modal_copy.embed_helper_text");
      embedBtn = embedLink;
      itemName = t("trix_editor.modal_copy.link");
      break;
    case "youtube":
      embedPlaceholder = pasteHttps;
      helperText = t("trix_editor.modal_copy.works_sharable_youtube");
      embedBtn = t("trix_editor.modal_copy.embed_video");
      itemName = t("trix_editor.modal_copy.youtube");
      break;
    case "wistia":
      embedPlaceholder = pasteHttps;
      helperText = t("trix_editor.modal_copy.works_wistia");
      embedBtn = t("trix_editor.modal_copy.embed_video");
      itemName = t("trix_editor.modal_copy.wistia");
      break;
    case "vimeo":
      embedPlaceholder = pasteHttps;
      helperText = t("trix_editor.modal_copy.works_vimeo");
      embedBtn = t("trix_editor.modal_copy.embed_video");
      itemName = t("trix_editor.modal_copy.vimeo");
      break;
    case "loom":
      embedPlaceholder = pasteHttps;
      helperText = t("trix_editor.modal_copy.works_loom");
      embedBtn = embedLink;
      itemName = t("trix_editor.modal_copy.loom");
      break;
    case "soundcloud":
      embedPlaceholder = pasteHttps;
      helperText = t("trix_editor.modal_copy.works_soundcloud");
      embedBtn = embedLink;
      itemName = t("trix_editor.modal_copy.soundcloud");
      break;
    case "spotify":
      embedPlaceholder = pasteHttps;
      helperText = t("trix_editor.modal_copy.works_spotify");
      embedBtn = embedLink;
      itemName = t("trix_editor.modal_copy.spotify");
      break;
    case "simplecast":
      embedPlaceholder = pasteHttps;
      helperText = t("trix_editor.modal_copy.works_simplecast");
      embedBtn = embedLink;
      itemName = t("trix_editor.modal_copy.simplecast");
      break;
    case "instagram":
      embedPlaceholder = pasteHttps;
      helperText = t("trix_editor.modal_copy.works_instagram");
      embedBtn = embedLink;
      itemName = t("trix_editor.modal_copy.instagram");
      break;
    case "facebook":
      embedPlaceholder = pasteHttps;
      helperText = t("trix_editor.modal_copy.works_facebook");
      embedBtn = embedLink;
      itemName = t("trix_editor.modal_copy.facebook");
      break;
    case "twitter":
      embedPlaceholder = t("trix_editor.modal_copy.twitter_link_placeholder");
      helperText = t("trix_editor.modal_copy.works_twitter");
      embedBtn = t("trix_editor.modal_copy.embed_twitter");
      itemName = t("trix_editor.modal_copy.twitter");
      break;
    case "typeform":
      embedPlaceholder = t("trix_editor.modal_copy.paste_typeform");
      helperText = t("trix_editor.modal_copy.works_typeform");
      embedBtn = embedLink;
      itemName = t("trix_editor.modal_copy.typeform");
      break;
    case "airtable":
      embedPlaceholder = t("trix_editor.modal_copy.paste_airtable");
      helperText = t("trix_editor.modal_copy.works_airtable");
      embedBtn = embedLink;
      itemName = t("trix_editor.modal_copy.airtable");
      break;
    case "videoask":
      embedPlaceholder = t("trix_editor.modal_copy.paste_videoask");
      helperText = t("trix_editor.modal_copy.works_videoask");
      embedBtn = t("trix_editor.modal_copy.embed_videoask");
      itemName = t("trix_editor.modal_copy.videoask");
      break;
    case "github":
      embedPlaceholder = pasteHttps;
      helperText = t("trix_editor.modal_copy.works_gist");
      embedBtn = t("trix_editor.modal_copy.embed_gist");
      itemName = t("trix_editor.modal_copy.github");
      break;
    case "googlemaps":
      embedPlaceholder = t("trix_editor.modal_copy.maps_placeholder");
      helperText = t("trix_editor.modal_copy.works_maps");
      embedBtn = t("trix_editor.modal_copy.embed_map");
      itemName = t("trix_editor.modal_copy.google_maps");
      break;
    case "codepen":
      embedPlaceholder = pasteHttps;
      helperText = t("trix_editor.modal_copy.works_codepen");
      embedBtn = embedLink;
      itemName = t("trix_editor.modal_copy.codepen");
      break;
    default:
      embedPlaceholder = t("trix_editor.modal_copy.https_placeholder");
      dropTitle = t("trix_editor.modal_copy.drop_file");
      embedBtn = t("trix_editor.modal_copy.embed");
      itemName = t("trix_editor.modal_copy.file");
  }

  return {
    embedPlaceholder,
    embedDesc,
    dropTitle,
    helperText,
    embedBtn,
    itemName,
  };
};
