import type { ChangeEvent, MouseEvent } from "react";
import { t } from "@/i18n-js/instance";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";
import { ImageError } from "./ImageError";

export interface EmbedUploadProps {
  addEmbed: (event: MouseEvent<HTMLButtonElement>) => void;
  embedUrl?: string;
  imageError?: string;
  onChangeEmbedUrl: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const EmbedUpload = ({
  addEmbed,
  embedUrl,
  imageError,
  onChangeEmbedUrl,
}: EmbedUploadProps) => (
  <>
    <ImageError error={imageError} />
    <input
      value={embedUrl}
      style={{ borderWidth: 1 }}
      className="upload-input placeholder:text-light placeholder:opacity-100"
      placeholder={t("image_upload.paste_image_file")}
      onChange={onChangeEmbedUrl}
      type="text"
    />
    <div className="flex w-full flex-row justify-center">
      <Button large variant="circle" disabled={!embedUrl} onClick={addEmbed}>
        {t("image_upload.embed_image")}
      </Button>
    </div>
    <div className="mt-4">
      <Typography.BodySm textAlign="text-center">
        {t("image_upload.works_with_image_type")}
      </Typography.BodySm>
    </div>
  </>
);
